import { Main, Item }     from 'components/List/Grid/Grid.style'
import { Content }        from 'components/List/Grid/Grid.Content'
import { get, floorplan } from 'components/List/List.Utilities'
import { FLOORPLAN }      from 'api/api'
import { active_unit, vis_desktop_nav }    from 'state/store.global'
import { useAtom }        from 'state/jotai'

export const Grid = ({ units, extra, pick, click }) => {
  
  const geta = useAtom(active_unit)[0]
  const [show, setShow] = useAtom(vis_desktop_nav);

  return (
    <>
      <Main>
        {          
          extra?.map((i, j) => {
            const onClick   = () => click(i.UnitID)
            const thumbnail = floorplan(i, FLOORPLAN)
            const item      = { key:i?.UnitID, style:pick, onClick  }
            const content   = { unit:i, thumbnail, color:get(i,'UnitColor') }            
            
            const isActive = geta.UnitID === i.UnitID
            const color = isActive ? {background:`${get(i,'UnitColor')}66`} : {}

            return (
              <Item {...item} color={get(i,'UnitColor')} style={color}>
                <Content {...content}/>
              </Item>
            )
        })}
        {
          units?.map((i, j) => {
            const onClick   = () => {click(i.UnitID); setShow(false);}
            const thumbnail = floorplan(i, FLOORPLAN)
            const item      = { key: i?.UnitID, onClick }
            const content   = { unit:i, thumbnail, color:get(i,'UnitColor') }

            const isActive = geta.UnitID === i.UnitID
            const color = isActive ? {background:`${get(i,'UnitColor')}66`} : {}

            return (
              <Item {...item} color={get(i,'UnitColor')} style={color}>
                <Content {...content}/>
              </Item>
           )
        })}
      </Main>
    </>
  )
}

